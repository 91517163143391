import React, { useEffect } from 'react';
import {ReactComponent as StarIcon} from "../../images/svg/star.svg"
import {ReactComponent as PlayIcon} from "../../images/svg/play.svg"
import {ReactComponent as MoreIcon} from "../../images/svg/more.svg"
import {ReactComponent as StarFill} from "../../images/svg/star-fill.svg"
import { useState } from 'react';

import { updateFavourites } from '../../CommonService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getFavandReminders } from '../../Redux/Favourites&Reminders/FavAndRemSlice.';
import { getFavourites } from '../../views/pages/Reminder&Fav/service';
import { useDispatch, useSelector } from 'react-redux';
const ShowCard = ({cardType,title,date,favourites,page,id,description,thumbnail,innerCard,navigate,data,showId}) => {
    const [starIconType,setStarIconType]=useState("lined")
    const [cardHoverClass,setCardHoverClass]=useState(false)
    const navigatePage=useNavigate()
    const dispatch=useDispatch()
    const user = useSelector((state) => state?.user?.value);
    const location=useLocation()
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      })
    const [favouriteStatus,setFavouriteStatus]=useState("0")
    
    useEffect(()=>{
        if(favourites){
            if(favourites=="1"){
                setFavouriteStatus("1")
            }else{
                setFavouriteStatus("0")
            }
        }
    },[favourites])
    useEffect(()=>{
        if(user){
            
            if(favourites=="1"){
                setStarIconType("fillRed")
            }else{
                setStarIconType("lined")
            }
        }else{
            setStarIconType("lined")
        }
    },[user])
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion])
    const addToFavourites=()=>{
        if(user){
            
            if(favouriteStatus=="0"){
                setStarIconType("fillRed")
                updateFavourites(showId,1).then((response)=>{
                    
                })
            }
        }else{
            navigatePage("/register-login",{state:{path:location?.pathname}})
        }
    }
    const removeFromFavourites=()=>{
        setStarIconType("lined")
        setFavouriteStatus("0")
        updateFavourites(showId,0).then((response)=>{
            if(location?.pathname=="/favourites"){

                getFavourites().then((favresponse)=>{
                    dispatch(getFavandReminders({
                        favouritesAndReminders:favresponse?.data
                    }))
                })
            }
        })
    }
    const scroll=()=>{
        if(location?.pathname=='/presenter-info'){

            document.documentElement.scrollTop = 0
        }
    }
    const replaceImage=(error)=>{
        error.target.src=require("../../images/thumb.png")
    }
  return (
    <div className={cardHoverClass?"showCard position-relative hovCard":"showCard position-relative"} onMouseOver={()=>setCardHoverClass(true)} onClick={scroll()} title={title} >
        <div className="top position-relative">
            {
                page!="videoplayer"?(

            <Link to={navigate=="show"?`/${id}`:navigate=="video"?`/watch-video/${id}`:navigate=="presenter"?"/presenters-info":"/article-info/"+id} state={navigate==="video"?{id:id}:{id:id}}>
                <div className="imageContainer">
                    {
                        innerCard=="innerCard" ?(
                            <img src={"https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/"+thumbnail} alt="Thumbnail" onError={(e)=>replaceImage(e)} />

                        ):(
                            <img src={thumbnail?thumbnail:"error"} alt="Thumbnail" onError={(e)=>replaceImage(e)} />

                        )
                    }
                </div>
            </Link>
                ):(
                    <div className="imageContainer">
                    {
                        innerCard=="innerCard" ?(
                            <img src={"https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/"+thumbnail} alt="Thumbnail" onError={(e)=>replaceImage(e)} />

                        ):(
                            <img src={thumbnail?thumbnail:"error"} alt="Thumbnail" onError={(e)=>replaceImage(e)} />

                        )
                    }
                </div>
                )
            }
            {
                cardType!="buttonCard"&&
                <>
                {
                    location?.pathname=="/favourites" &&
                    <span className={starIconType=="fillRed"?"star star-filled":"star"} onClick={()=>starIconType!="fillRed"?addToFavourites():removeFromFavourites()} onMouseOver={()=>starIconType!="fillRed"&&setStarIconType("fill")} onMouseOut={()=>starIconType!="fillRed"&&setStarIconType("lined")} >
               {starIconType=="lined"?(<StarIcon/>):(<StarFill />) }
            </span>
                }
                {
                    location?.pathname=="/featured-shows" &&
                    <span className={starIconType=="fillRed"?"star star-filled":"star"} onClick={()=>starIconType!="fillRed"?addToFavourites():removeFromFavourites()} onMouseOver={()=>starIconType!="fillRed"&&setStarIconType("fill")} onMouseOut={()=>starIconType!="fillRed"&&setStarIconType("lined")} >
               {starIconType=="lined"?(<StarIcon/>):(<StarFill />) }
            </span>
                }
                {innerCard!="innerCard"&&data?.show_id&&!data?.video_id&&<span className={starIconType=="fillRed"?"star star-filled":"star"} onClick={()=>starIconType!="fillRed"?addToFavourites():removeFromFavourites()} onMouseOver={()=>starIconType!="fillRed"&&setStarIconType("fill")} onMouseOut={()=>starIconType!="fillRed"&&setStarIconType("lined")} >
               {starIconType=="lined"?(<StarIcon/>):(<StarFill />) }
            </span>}
            <span className="playIcon">
                <div className="hoverEffect"></div>
                {
                    cardType=="descriptionCard"?(
                        <MoreIcon onClick={()=>navigatePage(navigate=="show"?`/${id}`:navigate=="video"?`/watch-video/${id}`:navigate=="presenter"?"/presenters-info":"/article-info/"+id,{state:{id:id}})}/>
                    ):(

                        <PlayIcon onClick={()=>page!="videoplayer"&&navigatePage(navigate=="show"?`/${id}`:navigate=="video"?`/watch-video/${id}`:navigate=="presenter"?"/presenters-info":"/article-info/"+id,{state:navigate === "video"?{id:id}:id})}/>
                    )
                }
            </span>
            </>
            }
        </div>
        {
            page!="videoplayer"?(

        <Link to={navigate=="show"?`/${id}`:navigate=="video"?`/watch-video/${id}`:navigate=="presenter"?"/presenters-info" : navigate == "news"? "/article-info/" + id: ""} state={navigate==="video"?{id:id}:{id:id}}>
        <div className={cardType=="buttonCard"?"bg-transparent bottom":cardType=="descriptionCard"?"bottom desc":"bottom dateCard"}>
            <h3 className={cardType=="buttonCard"?"title buttonCard":"title"} >{title}</h3>
            <div className="content">
                {cardType=="dateCard"&&<div className="dateSection">
                    <span className="icon">
                        <img src={require("../../images/icon-calendartime.png")} alt="Icon-Calender" />
                    </span>
                    <span className="date">{date}</span>
                </div>}
                {
                     cardType=="descriptionCard" &&(
                        <p>{description}</p>
                    )}{
                        cardType=="buttonCard"&&
                        <div className="buttonContainer">
                        <button>View Details</button>
                    </div>
                    
                }
            </div>
        </div>
        </Link>
            ):(
                <div className={cardType=="buttonCard"?"bg-transparent bottom":cardType=="descriptionCard"?"bottom desc":"bottom dateCard"}>
            <h3 className={cardType=="buttonCard"?"title buttonCard":"title"} >{title}</h3>
            <div className="content">
                {cardType=="dateCard"&&<div className="dateSection">
                    <span className="icon">
                        <img src={require("../../images/icon-calendartime.png")} alt="Icon-Calender" />
                    </span>
                    <span className="date">{date}</span>
                </div>}
                {
                     cardType=="descriptionCard" &&(
                        <p>{description}</p>
                    )}{
                        cardType=="buttonCard"&&
                        <div className="buttonContainer">
                        <button>View Details</button>
                    </div>
                    
                }
            </div>
        </div>
            )
        }
    </div>
  )
}

export default ShowCard
