import React, { useEffect, useRef, useState } from "react";
import * as service from "./service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import VideoJS from "../../../Components/VideoJsPlayer/VideoJS";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { playerToken } from "../../../CommonService";
import "react-lazy-load-image-component/src/effects/blur.css";
let img = [];
const IsraelTour = () => {
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();

  useEffect(() => {
    setLoading(true);
    for (let i = 1; i <= 144; i++) {
      img.push(`https://gizmeon.mdc.akamaized.net/revelationtv-static-files/${i}.jpg`);
    }
    service.getIsraelTourData().then((response) => {
      playerToken().then((tokenResponse) => {
        let arr = response?.data?.video?.video_name?.split("/").reverse();
        setVideoUrl(
          "https://poppo.tv/playlist/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            tokenResponse?.data?.data +
            "&type=video" +
            "&pubid=" +
            process.env.REACT_APP_PUBID
        );
      });
    });
  }, []);
  useEffect(() => {
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        poster: require("../../../images/thumb_israel.png"),
      };

      setVideoPlayer(<VideoJS options={videoJsOptions} />);
      setLoading(false);
    }
  }, [videoUrl]);

  const replaceImage = (error) => {
    error.target.src = require("../../../images/11.jpg");
  };

  if (loading) {
    return (
      <div className="loadingContainer">
        <div className="loadingOverlay"></div>
        <div className="spinnerIcon">
          <div className="inner lds-dual-ring"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="israel">
      <div className="wrapper">
        <h1 className="heading">Israel Tour</h1>
        <div className="videoContainer">{videoPlayer}</div>
        <div className="details">
          <h1 className="title">Church Without Walls</h1>
          <span>Israel & Jordan Tour 2024</span>
          <span>Including Jerusalem, Bethlehem, Galilee, Tel Aviv/Jaffa, Dead Sea and Jordan Option</span>
          <span>20-29 February</span>
          <p>For full tour INFORMATION and BOOKINGS contact Travelink on 020 8931 8811</p>
          <p>
            Join the Church Without Walls for a wonderful time of fellowship, touring and fun when we embark on our tour
            of Israel and Jordan. We all fly out together from Heathrow Airport and start our first day in JERUSALEM - a
            bustling capital of the State of Israel with every corner of the Jewish world living side by side. One of
            the most awe inspiring views in the world is standing on the Mount of Olives looking down to the Old City
            stamped with the vast Temple Mount and patchwork of walls, houses, churches and domes - not least the
            imposing Golden Dome of the Rock where Abraham told Isaac, "God will provide the sacrifice". We will trace
            the geography of the Last Supper, the betrayal and arrest of Jesus, the false accusations before the High
            Priest Caiaphas, the Crucifixion and, most movingly, hold our communion service near the empty Garden Tomb
            where the stone has been rolled away and a message on the door states ‘He is not here. He is Risen!’ In
            addition we will travel to BETHLEHEM to visit the ancient Church of the Nativity, Manger Square and the
            Shepherd’s Field.
          </p>
          <p>
            As we travel around a modern country with roads, railways, bridges, museums and high tech infrastructure -
            singing praises on our coaches - it is not hard to see evidence of where history connects to the Bible. Take
            the former great port city of CAESAREA (named in honour of Caesar Augustus) just north of Tel Aviv where the
            Apostle Paul was tried. Or just south of Tel Aviv the ancient harbour of Jaffa - Joppa in Jonah’s time -
            where Peter had a vision of the gospel reaching the gentiles (and where Emperor Napoleon called for the
            return of the Jews to their homeland). Or in the far north near Mt Hermon where remains of Caesarea Phillipi
            (a name including the son of Herod the Great) mark where Jesus promised he would build his church. We are
            living evidence that he did it!
          </p>
          <p>
            On the northern leg of our tour we see the beauty and diversity of the promised land. The SEA of GALILEE
            sits as a glistening jewel around which the Lord spent most of his three years of ministry. How could we not
            take a boat ride to see the panorama where even the wind and waves obeyed him. And then the JORDAN BAPTISMS
            where we consider what the Lord requires of us in these days. We travel through hills and valleys, rivers
            and forests, flora and fauna, birds and even wild animals (on occasions) in nature reserves witnessing the
            land of miracles. Swampland reclaimed, hills re-afforested, deserts blossoming, waste places rebuilt all
            testify to the fulfilment of the prophecies.
          </p>
          <p>
            For this tour we have included a visit to the dramatic fortress of MASADA with it tragic history of Roman
            conquest. On the same day we will visit the nearby caves of QUMRAN where the Essenes preserved the Dead Sea
            Scrolls and fit in time for a swim (or rather float) in the Dead Sea.
          </p>
          <p>
            <b>
              A SPECIAL OPTION will be to cross over to JORDAN to visit Mt NEBO where Moses was able to view the
              Promised Land (though not cross over himself) and also the ancient MADABA Mosaic Map showing Jerusalem at
              the centre. And of course the UNESCO heritage city of PETRA carved into the rock with the many layers of
              history in an awe inspiring landscape.
            </b>
          </p>
          <p>
            What a wonderful blessing it is each year by God’s grace to escape the social, economic, cultural and
            political challenges of our home lives and enter a space that is filled with the story of God’s
            righteousness, truth and justice for his people and the church. Our hearts are lifted to a higher place as
            we consider his grace and forgiveness in places where the gospel was lived out. We are reminded how relevant
            it is to all our lives in the 21st Century - especially in this time of great upheaval - that God who keeps
            Israel will keep our going out and our coming in now and for evermore.
          </p>
          <p>
          For full tour information and Bookings Contact Travelink on 020 8931 8811
          </p>
          <p>Click <span className="link" onClick={()=>window.open("https://www.revelationtv.com/magazine/Israel2024.pdf","_blank")}>here</span> for Tour Brochure.</p>
        </div>
      </div>
      {!loading && (
        <div className="gallery">
          <Swiper
            slidesPerView={2}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            spaceBetween={0}
            breakpoints={{
              320: {
                slidesPerView: 1,
                slidesPerColumn: 1,
              },
              640: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
            loop={false}
          >
            <div className="left"></div>
            <div className="right"></div>

            {img?.length > 0 &&
              img?.map((img, index) => (
                <SwiperSlide key={index}>
                  <div className="imageContainer">
                    <LazyLoadImage
                      effect="blur"
                      src={img}
                      alt="Photo"
                      onError={(e) => replaceImage(e)}
                      placeholderSrc={"https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif"}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default IsraelTour;
