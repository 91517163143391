import React, { useEffect, useState , useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg";
import {ReactComponent as PlayIcon} from "../../../images/svg/play.svg";
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg";
import ShowCard from '../../../Components/ShowCard/ShowCard';
import { Link, useLocation, useNavigate,useParams } from 'react-router-dom';
import { getShowDetails } from '../../../CommonService';
import { FacebookShareButton, TwitterShareButton,EmailShareButton,WhatsappShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import parse from "html-react-parser";

const ShowInformation = ({cardType,title,year,description,thumbnail}) => {
    const navigate=useNavigate();
    const location=useLocation()
    const {show_id}=useParams()
    const [showDetails,setShowDetails]=useState()
    const [episodes,setEpisodes]=useState([])
    const [sliceCount,setSliceCount]=useState(8)
    const [slicedEpisodes,setSlicedEpisodes]=useState()
    const [shareButton,setShareButton]=useState(false)
    const [loading,setLoading]=useState(true)
    const [firstVideoId,setFirstVideoId] = useState()

    let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);

    
    useEffect(()=>{
        window.scroll(0,0)
        setLoading(true)
        getShowDetails(show_id).then((response)=>{
            if(response?.success===true){

                setShowDetails(response?.data)
                setEpisodes(response?.data?.videos)
                setLoading(false)
                if(response?.data?.single_video===0){
                    if(response?.data?.videos[0]?.videos[0]?.vanity_url){
                        setFirstVideoId(response?.data?.videos[0]?.videos[0]?.vanity_url)
                    }else{
                        setFirstVideoId(response?.data?.videos[0]?.videos[0]?.video_id)
    
                    }
                }else{
                    if(response?.data?.videos[0]?.vanity_url){
                        setFirstVideoId(response?.data?.videos[0]?.vanity_url)
                    }else{
                        setFirstVideoId(response?.data?.videos[0]?.video_id)
                    }
                }
            }else{
                navigate("*")
            }

        })
    },[location?.pathname])
    const showDetailByEpisode=()=>{

    }
    useEffect(()=>{
        if(showDetails?.single_video!=0){
            let newSlicedEpisodes=episodes?.slice(0,10)
            setSlicedEpisodes(newSlicedEpisodes)
        }
    },[showDetails])
    
    const showMoreHandler=()=>{
        setSliceCount(sliceCount+8)
    }

    if(loading){
        return(
            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        )
    }
  return (
    <div className='show-information'>
        <div className="wrapper">
            <div className="spotlight">
                
                            <div className="featuredContainer">
                                <div className="background">
                                    <img src={"https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/"+showDetails?.logo_thumb} alt="Thumbnail" />
                                </div>
                                <div className="imageContainer">
                                    <img src={"https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/"+showDetails?.logo_thumb} alt="Thumbnail" />
                                </div>
                                <div className="gradientBg"></div>
                            </div>
                    
                <div className="details">
                    <div className="left">
                        <h1 className="title">
                            {showDetails?.show_name}
                        </h1>
                        <p>{parse(showDetails?.synopsis.split("\n").join("<br>"))}</p>
                    </div>
                    <div className="right">
                        <div className="shareButtonContainer">

                        <button ref={shareIconRef} className={shareButton?"active transparent ":"transparent"} onClick={()=>setShareButton(!shareButton)} >
                            <ShareIcon className='share'/>
                            <span>Share</span>
                            
                            <DownArrow className='active'/>
                        </button>
                        {
                            shareButton &&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                            <WhatsappShareButton  url={window.location.href}>
                          <span className='name whatsapp'>Whatsapp</span>
                          </WhatsappShareButton   >
                        </div>
                        }
                        </div>
                        <button className="bg-button" onClick={()=>navigate(`/watch-video/${firstVideoId}`,{state:show_id})}>
                            Play
                            <PlayIcon className='play'/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="scheduleContainer">
                <h1 className='heading'></h1>
                <div className="detailsContainer">
                    {
                        showDetails?.schedule&&
                    <div className="left">
                        <h1>New Episodes</h1>
                        <div className="scheduleTime">
                            {
                                showDetails?.schedule?.map((item,index)=>(
                                    item?.times?.map((timesData,index)=>(

                                        <span  key={index} className='top'>{item?.weekday} - <span className="time">{timesData}</span></span>
                                    ))
                                ))
                            }
                            
                        </div>
                    </div>
                    }
                    {
                        showDetails?.rerun&&

                    <div className="right">
                            <h1>Rerun</h1>
                        <div className="scheduleTime">
                        {
                                showDetails?.rerun?.map((item,index)=>(
                                    item?.times?.map((timesData,index)=>(

                                        <span key={index} className='top'>{item?.weekday} - <span className="time">{timesData}</span></span>
                                    ))
                                ))
                            }
                                

                        </div>
                    </div>
                    }

                </div>
            </div>
            <div className="librarySection position-relative">
                <h1 className="heading">
                    Episodes
                </h1>
                <div className="libraries">
                {
                        showDetails?.single_video==0 ?
                        episodes?.map((video,key)=>{
                            return video?.videos
                        }).map((data,index)=>{
                            return data?.slice(0,sliceCount)
                            
                        }).map((slicedData,index)=>{
                            return slicedData?.map((vd,index)=>(
                                <div className="items" key={index} onClick={()=>showDetailByEpisode(vd)}>
                                    <ShowCard 
                                        cardType="dateCard"
                                        thumbnail={vd?.thumbnail_350_200}
                                        title={vd?.video_title}
                                        description={vd?.video_description}
                                        innerCard={"innerCard"}
                                        navigate="video"
                                        id={vd?.vanity_url?vd?.vanity_url:vd?.video_id}
                                        date={vd?.schedule_date}
                                        videoData={vd}

                                    />
                                </div>

                            ))
                        })
                        :
                        slicedEpisodes?.map((video,key)=>(
                            <div className="items" key={key} onClick={()=>showDetailByEpisode(video)}>
                                    <ShowCard 
                                        cardType="dateCard"
                                        thumbnail={video?.thumbnail_350_200}
                                        title={video?.video_title}
                                        description={video?.video_description}
                                        innerCard="innerCard"
                                        date={video?.schedule_date}
                                        videoData={video}
                                        id={video?.vanity_url?video?.vanity_url:video?.video_id}
                                        navigate="video"

                                    />
                                </div>
                        ))
                    }
                    
                    
                </div>
                {
                    showDetails?.single_video==0&&episodes[0]?.videos?.length>sliceCount&&
            <div className="gradientBg">
                    <div className="buttonContainer text-center">
                        <button className="showMore mt-5" onClick={()=>showMoreHandler()}>
                            Show More
                            <DownArrow/>
                        </button>
                    </div>
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ShowInformation